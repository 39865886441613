import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'semantic-ui-react';
import { collection, getDocs } from 'firebase/firestore';
import { addBoardGame } from '../../services/bars';
import "./BoardGameModal.css";
import BoardGameCard from '../cards/BoarGameCard';
import { firestore } from '../../services/firebase';
import useBarAuthUser from '../../stores/bar_auth_store';
import useMessageStore from '../../stores/message_store';


const BoardGameModal = ({ isOpen, onClose ,barId, }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [boardGames, setBoardGames] = useState([]);
  const [filteredBoardGames, setFilteredBoardGames] = useState([]);
  const { barUser, updateBarUser } = useBarAuthUser();
  const { showMessage } = useMessageStore();

  useEffect(() => {
    const fetchBoardGames = async () => {
      const boardGamesCollection = collection(firestore, 'boardgames');
      const boardGamesSnapshot = await getDocs(boardGamesCollection);
      const boardGamesList = boardGamesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBoardGames(boardGamesList);
      setFilteredBoardGames(boardGamesList);
    };

    fetchBoardGames();
  }, []);

  useEffect(() => {
    setFilteredBoardGames(
      boardGames.filter(game =>
        game.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, boardGames]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (game) => {
    if(barUser.ludotheque.includes(game.id)){

      showMessage('Aucun jeu trouvé', 'error');
      return
    }
    addBoardGame(barId, game.id);
    updateBarUser();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose} className='custom-modal'>
      <Modal.Header>Ajouter un Jeu</Modal.Header>
      <Modal.Content>
        <Input
          icon="search"
          placeholder="Rechercher un jeu..."
          value={searchTerm}
          onChange={handleSearchChange}
          fluid
        />
        {boardGames.length === 0 ? (
          <p>Chargement...</p>
        ) : (
          <div className="list-games">
          {filteredBoardGames.map(game => (
              <BoardGameCard boardGame={game} handleSelect={handleSelect} />
            ))}
        </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Fermer</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BoardGameModal;
