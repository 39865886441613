import React from 'react';
import useMessageStore from '../stores/message_store';
import './message.css'; // Assurez-vous d'importer le fichier CSS

const MessageBanner = () => {
  const message = useMessageStore((state) => state.message);
  const type = useMessageStore((state) => state.type);

  if (!message) return null;

  const bannerClass = type === 'error' ? 'banner banner-error' : 'banner banner-success';

  return (
    <div className={bannerClass}>
        {type !== 'error' ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
}
      {message}
    </div>
  );
};

export default MessageBanner; 
