// Analytics.js
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./BarAnalyticScreen.css";
import { Icon } from "semantic-ui-react";
import Sidebar from "../../components/navBars/Sidebar";
import useBarAuthStore from "../../stores/bar_auth_store";
import { fetchAnalyticsData } from "../../services/bars";
import {
  formatEventsForBookingChart,
  formatEventsForNewPlayersChart,
  formatEventsForNbPlayersChart,
  calculateEventVariation,
  getFidelity,
  getFidelityVariation,
  getNbBookingsThisMonth,
  getNbBookingsThisMonthVariation,
  getNbDifferentPlayers,
  getNbEventsValidated,
  getNbEventsValidatedVariation,
} from "../../utils/analytics";
import BoardGameCard from "../../components/cards/BoarGameCard";
import LoadingScreen from "../general/LoadingScreen";
import LoggedButNotSubscribedScreen from "../general/LoggedButNotSubscribedScreen";

const BarAnalyticsScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [popularGames, setPopularGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingRange, setBookingRange] = useState("30days");
  const [playerRange, setPlayerRange] = useState("30days");
  const [newPlayersRange, setNewPlayersRange] = useState("30days");
  const { barUser } = useBarAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { events, mostPopularGames } = await fetchAnalyticsData(
          barUser.id
        );
        setBookings(events);
        setPopularGames(mostPopularGames);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };
    fetchData();
  }, [barUser.id]);

  if (!barUser.isSubscribed) {
    return <LoggedButNotSubscribedScreen />;
  }

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Sidebar />
      <div className="analytics-content">
        <div className="bar-header">
          <Icon name="info circle" size="large" color="blue" />
          <h4>Bienvenue sur vos analytics.</h4>
        </div>
        <div className="charts">
          <div className="chart card">
            <h3>Nombre de réservation</h3>
            <div className="chart-controls">
              <button
                className="chart-control-button"
                onClick={() => setBookingRange("12months")}
              >
                12 mois
              </button>
              <button
                className="chart-control-button"
                onClick={() => setBookingRange("30days")}
              >
                30 jours
              </button>
              <button
                className="chart-control-button"
                onClick={() => setBookingRange("7days")}
              >
                7 jours
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={formatEventsForBookingChart(bookings, bookingRange)}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="reservations"
                  stroke="#4373a7"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="chart card">
            <h3>Nouveau joueurs</h3>
            <div className="chart-controls">
              <button
                className="chart-control-button"
                onClick={() => setNewPlayersRange("12months")}
              >
                12 mois
              </button>
              <button
                className="chart-control-button"
                onClick={() => setNewPlayersRange("30days")}
              >
                30 jours
              </button>
              <button
                className="chart-control-button"
                onClick={() => setNewPlayersRange("7days")}
              >
                7 jours
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={formatEventsForNewPlayersChart(bookings, newPlayersRange)}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="nouveaux_joueurs"
                  stroke="#4373a7"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="chart card">
            <h3>Nombre de joueurs</h3>
            <div className="chart-controls">
              <button
                className="chart-control-button"
                onClick={() => setPlayerRange("12months")}
              >
                12 mois
              </button>
              <button
                className="chart-control-button"
                onClick={() => setPlayerRange("30days")}
              >
                30 jours
              </button>
              <button
                className="chart-control-button"
                onClick={() => setPlayerRange("7days")}
              >
                7 jours
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={formatEventsForNbPlayersChart(bookings, playerRange)}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="joueurs"
                  stroke="#4373a7"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="stats">
            <div className="stat card">
              <h3>
                {bookings.length}{" "}
                <span
                  className={
                    calculateEventVariation(barUser.bookings).includes("+")
                      ? "positive-percent"
                      : "negative-percent"
                  }
                >
                  {calculateEventVariation(barUser.bookings)}
                </span>
              </h3>
              <p>réservations total</p>
            </div>
            <div className="stat card">
              <h3>
                {getFidelity(bookings)}%{" "}
                <span
                  className={
                    getFidelityVariation(bookings).includes("+")
                      ? "positive-percent"
                      : "negative-percent"
                  }
                >
                  {getFidelityVariation(bookings)}
                </span>
              </h3>
              <p>Fidélité des joueurs</p>
            </div>
            <div className="stat card">
              <h3>
                {getNbBookingsThisMonth(bookings)}{" "}
                <span
                  className={
                    getNbBookingsThisMonthVariation(bookings).includes("+")
                      ? "positive-percent"
                      : "negative-percent"
                  }
                >
                  {getNbBookingsThisMonthVariation(bookings)}
                </span>
              </h3>
              <p>Réservations ce mois-ci</p>
            </div>
            <div className="stat card">
              <h3>
                {getNbDifferentPlayers(bookings)}
                <span
                  className={
                    getNbBookingsThisMonthVariation(bookings).includes("+")
                      ? "positive-percent"
                      : "negative-percent"
                  }
                >
                  {getNbBookingsThisMonthVariation(bookings)}
                </span>
              </h3>
              <p>Nombre total de joueurs différents</p>
            </div>
            <div className="stat card">
              <h3>
                {getNbEventsValidated(bookings)}
                <span
                  className="positive-percent"
                >
                  {getNbEventsValidatedVariation(bookings)}
                </span>
              </h3>
              <p>Nombre total d'evenements validés</p>
            </div>
          </div>

          <div className="chart card">
            <h3>Jeux les plus populaires</h3>
            <div className="games">
              {popularGames.map((game) => (
                <BoardGameCard
                  boardGameId={game.gameId}
                  handleSelect={() => null}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarAnalyticsScreen;
