// authStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { auth } from "../services/firebase"; // Assurez-vous de configurer Firebase correctement
import { Bar } from "../models/bar_model";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../services/firebase";

const useBarAuthStore = create(
  persist(
    (set, get) => ({
      email: "",
      barUser: null,
      isAuthenticated: null,
      setEmail: (email) => set({ email }),
      setBarUser: (barUser) => set({ barUser }),
      setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
      getIsAuthenticated: () => {
        set({ isAuthenticated: null });
        const user = auth.currentUser;
        if (user) {
          set({ isAuthenticated: true });
        } else {
          set({ isAuthenticated: false });
        }
      },
      updateBarUser: () => {
        const { barUser } = get();
        if (!barUser || !barUser.id) {
          console.error("barUser or barUser.id is not defined");
          return;
        }

        const barId = barUser.id;
        const barDocRef = doc(firestore, "bars", barId);
        return onSnapshot(barDocRef, (snapshot) => {
          if (snapshot.exists()) {
            var bar = Bar.fromFirestore(snapshot.id, snapshot.data())
            set({ barUser: bar });
          } else {
            set({ barUser: null });
          }
        });
      },
    }),

    {
      name: "bar-auth-storage", // Nom sous lequel l'état sera stocké dans le localStorage
      getStorage: () => localStorage, // Utilise localStorage pour persister l'état
    }
  )
);

export default useBarAuthStore;
