import React, { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import "./BarProfileScreen.css";
import Sidebar from "../../components/navBars/Sidebar";
import { Icon } from "semantic-ui-react";
import useBarAuthStore from "../../stores/bar_auth_store";
import { updateBarField } from "../../services/bars";
import useMessageStore from "../../stores/message_store";
import BoardGameCard from "../../components/cards/BoarGameCard";
import BoardGameModal from "../../components/modals/BoardGameModal";
import { Segment, Checkbox } from "semantic-ui-react";
import { removeShowDetails } from "../../utils/barUtils";
import { getBarSchedule } from "../../services/bars";
import BillingPortalButton from "../../components/subscriptions/BillingPortalButton";
import StripeCheckoutButton from "../../components/subscriptions/StripeCheckoutButton";

const BarProfileScreen = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { barUser, updateBarUser } = useBarAuthStore();
  const [name, setName] = useState(barUser.name);
  const [address, setAddress] = useState(barUser.address);
  const [city, setCity] = useState(barUser.city);
  const [country, setCountry] = useState(barUser.country);
  const { showMessage } = useMessageStore();
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour la modale

  const handleEdit = () => {
    if (isEditing) {
      // Sauvegarder les modifications
      if (name !== barUser.name) {
        // Mettre à jour le barUser
        updateBarField(barUser.id, "name", name);
      }
      if (address !== barUser.address) {
        updateBarField(barUser.id, "address", address);
      }
      if (city !== barUser.city) {
        updateBarField(barUser.id, "city", city);
      }
      if (country !== barUser.country) {
        updateBarField(barUser.id, "country", country);
      }
      if (
        name !== barUser.name ||
        address !== barUser.address ||
        city !== barUser.city ||
        country !== barUser.country
      ) {
        updateBarUser();
        showMessage("Profil mis à jour avec succès", "success");
      }
    }
      setName(barUser.name);
      setAddress(barUser.address);
      setCity(barUser.city);
      setCountry(barUser.country);
    setIsEditing(!isEditing);
  };

  const handleDelete = async (boardgameId) => {
    var ludotheque = barUser.ludotheque;
    ludotheque = ludotheque.filter((game) => game !== boardgameId);
    await updateBarField(barUser.id, "ludotheque", ludotheque);
    await updateBarUser();
  };

  const handleChange = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "city":
        setCity(e.target.value);
        break;
      case "country":
        setCountry(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="profile-content">
        <div className="bar-header">
          <Icon name="info circle" size="large" color="blue" />
          <h4>Bienvenue sur votre tableau de bord.</h4>
        </div>

        <div className="profile-header card">
          <div className="profile-info">
            <Icon size="huge" name="user circle" />
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleChange(e, "name")}
                  className="profile-input"
                />
                <input
                  type="text"
                  value={address}
                  onChange={(e) => handleChange(e, "address")}
                  className="profile-input"
                />
                <input
                  type="text"
                  value={city}
                  onChange={(e) => handleChange(e, "city")}
                  className="profile-input"
                />
                <input
                  type="text"
                  value={country}
                  onChange={(e) => handleChange(e, "country")}
                  className="profile-input"
                />
              </div>
            ) : (
              <div
                style={{
                  textAlignLast: "start",
                }}
              >
                <h3>{barUser.name}</h3>
                <p>{barUser.address}</p>
                <p>{barUser.city}</p>
                <p>{barUser.country}</p>
              </div>
            )}
            <FaPencilAlt className="edit-icon" onClick={handleEdit} />
          </div>
        </div>

        <div className="subscription card">
          <p>
            {barUser.isSubscribed
              ? "Vous êtes abonné"
              : "Vous n'êtes pas abonné"}
          </p>
          {barUser.isSubscribed
              ? <BillingPortalButton />
              : <StripeCheckoutButton />}
        </div>

        <Horaires barId={barUser.id} />

        <div className="ludotheque card">
          <h3>
            Ludothèque
            <span className="add-game" onClick={() => setIsModalOpen(true)}>
              +
            </span>
          </h3>
          <div className="games">
            {barUser.ludotheque.map((game) => (
              <BoardGameCard
                boardGameId={game}
                handleSelect={() => null}
                handleDelete={(boardgameId) => handleDelete(boardgameId)}
              />
            ))}
          </div>
        </div>
        <BoardGameModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          barId={barUser.id}
          updateBarUser={updateBarUser}
        />
      </div>
    </div>
  );
};

export default BarProfileScreen;

// Horaire
const Horaires = (props) => {
  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const [schedule, setSchedule] = useState({
    Lundi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Mardi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Mercredi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Jeudi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Vendredi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Samedi: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
    Dimanche: {
      enabled: false,
      openAm: "",
      closeAm: "",
      openPm: "",
      closePm: "",
      showDetails: false,
    },
  });

  useEffect(() => {
    const fetchSchedule = async () => {
      const schedule =  await getBarSchedule(props.barId);
    setSchedule(schedule);
    }
    fetchSchedule();
  })

  const handleToggle = (day) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          enabled: !prevSchedule[day].enabled,
          showDetails: !prevSchedule[day].enabled,
        },
      };
      const scheduleForFirestore = removeShowDetails(updatedSchedule);
      updateBarField(props.barId, "schedule", scheduleForFirestore);
      return updatedSchedule;
    });
  };

  const handleTimeChange = (day, type, value) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          [type]: value,
        },
      };
      const scheduleForFirestore = removeShowDetails(updatedSchedule);
      updateBarField(props.barId, "schedule", scheduleForFirestore);
      return updatedSchedule;
    });
  };

  return (
    <div className="schedule card">
      <h3>Horaires</h3>
      {days.map((day) => (
        <Segment
          key={day}
          className={`day ${schedule[day].enabled ? "active" : ""}`}
        >
          <div className="day-header">
            <Checkbox
              toggle
              checked={schedule[day].enabled}
              onChange={() => handleToggle(day)}
              label={day}
            />
          </div>
          {schedule[day].enabled && schedule[day].showDetails && (
            <div className="time-inputs">
              <div className="time-pair">
                <label>Matin :</label>
                <select
                  value={schedule[day].openAm}
                  onChange={(e) =>
                    handleTimeChange(day, "openAm", e.target.value)
                  }
                >
                  <option value="">Ouverture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
                à
                <select
                  value={schedule[day].closeAm}
                  onChange={(e) =>
                    handleTimeChange(day, "closeAm", e.target.value)
                  }
                >
                  <option value="">Fermeture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
              </div>
              <div className="time-pair">
                <label>Soir :</label>
                <select
                  value={schedule[day].openPm}
                  onChange={(e) =>
                    handleTimeChange(day, "openPm", e.target.value)
                  }
                >
                  <option value="">Ouverture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
                à
                <select
                  value={schedule[day].closePm}
                  onChange={(e) =>
                    handleTimeChange(day, "closePm", e.target.value)
                  }
                >
                  <option value="">Fermeture</option>
                  {[...Array(13).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </Segment>
      ))}
    </div>
  );
};
