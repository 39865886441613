// Dashboard.js
import React from "react";
import Sidebar from "../../components/navBars/Sidebar.js";

const LoggedButNotSubscribedScreen = () => {

  return (
    <div>
      <Sidebar />
      <div style={
        {
          marginLeft:"250px",
          // set to center
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }
      }>
        <h1>Vous n'êtes pas encore abonné</h1>
        <p>Vous devez souscrire à un abonnement pour accéder à cette page. Rendez-vous sur la page "Profil"</p>
      </div>
    </div>
  );
};

export default LoggedButNotSubscribedScreen;
