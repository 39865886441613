import { React, useState } from "react";
import { verifyPasswordFormat } from "../../utils/stringVerification";
import { signup } from "../../services/auth";
import { Icon } from "semantic-ui-react";

const BarSignupScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordFormatError, setPasswordFormatError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      id="BarSingupScreen"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <h1>Section Bar</h1>
      <h2>
        Bienvenue ! Inscrivez-vous et faites croitre votre activité avec Ludomit
      </h2>
      <label htmlFor="email">Email</label>
      <input
        className="input"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br />
      <label htmlFor="password">Mot de passe</label>
      <div class="password_input">
        <input
          className="input"
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            e.target.value !== confirmPassword
              ? setconfirmPasswordError(
                  "Les mots de passe ne correspondent pas"
                )
              : setconfirmPasswordError("");
            e.target.value !== ""
              ? setPasswordFormatError(verifyPasswordFormat(e.target.value))
              : setPasswordFormatError("");
          }}
          required
        />
        <Icon id="eye_icon" name={!showPassword ? "eye" : "eye slash"} size="large" onClick={() => setShowPassword(!showPassword)} />
      </div>
      {passwordFormatError !== ""
        ? passwordFormatError
            .split("\n")
            .map((e) => <p className="error">{e}</p>)
        : null}
      <br />
      <label htmlFor="password">Confirmer le mot de passe</label>
      <div class="password_input">
        <input
          className="input"
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            if (password !== e.target.value) {
              setconfirmPasswordError("Les mots de passe ne correspondent pas");
            } else {
              setconfirmPasswordError("");
            }
          }}
          required
        />
          <Icon id="eye_icon" name={!showPassword ? "eye" : "eye slash"} size="large" onClick={() => setShowPassword(!showPassword)} />
      </div>

      {confirmPasswordError !== "" ? (
        <p className="error">{confirmPasswordError}</p>
      ) : null}
      <br />
      <button
        className="small_button_blue_background"
        type="submit"
        onClick={(e) => signup(email, password, confirmPassword)}
      >
        Connexion
      </button>
      <button className="small_button_transparent_background" onClick={()=> window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/login"}>
        Déja un compte ? Connectez-vous
      </button>
    </div>
  );
};

export default BarSignupScreen;
