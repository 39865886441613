// Dashboard.js
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { getUserWithId } from "../../services/users";
import { removeNotificationAtIndex } from "../../services/bars";

const NotificationCard = (props) => {
  const [username, setUsername] = useState("");
  const { notificationData, barId, index, eventTimestamp, date } = props;

  useEffect(() => {
    async function fetchData() {
      const user = await getUserWithId(
        notificationData.userId
      );
      setUsername(user.username ?? "Utilisateur inconnu");
    }
    fetchData();
  }, [username, notificationData, index, barId]);

  const getNotificationMessage = (type) => {
    switch (type) {
      case "creation":
        return " a créé un événement";
      case "modification":
        return " a modifié un événement";
      default:
        return "";
    }
  };

  const fromSecondsToDate = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} à ${date.getHours()}:${date.getMinutes()}`;
  };




  if (username === "") {
    return (
      <div>
        <li key={notificationData}>
          <p>Chargement...</p>
        </li>
      </div>
    );
  } else {
    return (
      <li onClick={() => window.location.href = process.env.REACT_APP_URL_PREFIX + "/bar/booking?timestamp=" + eventTimestamp } key={notificationData}>
        <FaTrash color="red" className="notification-icon" onClick={()=>{
          removeNotificationAtIndex(barId, index);
        }} />
        {username +
          getNotificationMessage(notificationData.type)}
        <p style={{
          fontSize: "0.8rem",
          color: "grey",
          marginTop: "0.2rem",
          marginLeft: "0.5rem"
        }}>{fromSecondsToDate(date)}</p>
      </li>
    );
  }
};

export default NotificationCard;
