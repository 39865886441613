import React from 'react';
import './Sidebar.css'; // Assurez-vous d'ajouter les styles CSS
import { logout } from '../../services/auth';
import { Icon } from 'semantic-ui-react';
import useBarAuthStore from '../../stores/bar_auth_store';

const Sidebar = () => {
  const { barUser } = useBarAuthStore();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
      <Icon size='huge' name='user circle' />
        <h2>{barUser.name}</h2>
      </div>
      <hr />
      <ul className="sidebar-menu">
        <li className="sidebar-item">
          <a href="/bar/dashboard">
            <span className="icon">🏠</span> Tableau de bord
          </a>
        </li>
        <li className="sidebar-item">
          <a href="/bar/booking">
            <span className="icon">📅</span> Réservation
          </a>
        </li>
        <li className="sidebar-item">
          <a href="/bar/analytics">
            <span className="icon">📊</span> Analytics
          </a>
        </li>
        <li className="sidebar-item">
          <a href="/bar/profile">
            <span className="icon">👤</span> Profil
          </a>
        </li>
      </ul>
      
      <div className="sidebar-footer">
      <hr />
        <button className="logout-button" onClick={()=>logout()}><span className="icon">👋</span>Déconnexion</button>
        <a href="/test">
          <span className="icon">🔧</span> Test
        </a>
        <a href="/support">
          <span className="icon">🛠️</span> Support
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
