class BoardGame {
    constructor({
      id,
      name,
      nameLowercase,
      age,
      author,
      category,
      comments,
      createdAt,
      designer,
      dislikes,
      duration,
      image,
      likes,
      likesCount,
      link,
      ludomitReview,
      listUserRating,
      rating,
      nbPlayers,
      playCount,
      publishers,
      reviews,
    }) {
      this.id = id;
      this.name = name;
      this.nameLowercase = nameLowercase;
      this.age = age;
      this.author = author;
      this.category = category;
      this.comments = comments || [];
      this.createdAt = createdAt;
      this.designer = designer;
      this.dislikes = dislikes || [];
      this.duration = duration;
      this.image = image;
      this.likes = likes || [];
      this.likesCount = likesCount || 0;
      this.link = link;
      this.ludomitReview = ludomitReview || {};
      this.listUserRating = listUserRating || [];
      this.rating = rating;
      this.nbPlayers = nbPlayers;
      this.playCount = playCount || 0;
      this.publishers = publishers;
      this.reviews = reviews || [];
    }
  
    // Méthode statique pour créer une instance à partir des données Firestore
    static fromFirestore(doc, docId) {
      const data = doc;
      return new BoardGame({
        id: docId,
        name: data.name,
        nameLowercase: data.nameLowercase,
        age: data.age,
        author: data.author,
        category: data.category,
        comments: data.comments,
        createdAt: data.createdAt,
        designer: data.designer,
        dislikes: data.dislikes,
        duration: data.duration,
        image: data.image,
        likes: data.likes,
        likesCount: data.likesCount,
        link: data.link,
        ludomitReview: data.ludomitReview,
        listUserRating: data.listUserRating,
        rating: data.rating,
        nbPlayers: data.nbPlayers,
        playCount: data.playCount,
        publishers: data.publishers,
        reviews: data.reviews,
      });
    }
  }
  
  export default BoardGame;
  