// Dashboard.js
import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaTimes } from "react-icons/fa";
import "./BarDashboardScreen.css";
import Sidebar from "../../components/navBars/Sidebar.js";
import useBarAuthStore from "../../stores/bar_auth_store.js";
import {
  calculateEventVariation,
  formatEventsForLineChart,
} from "../../utils/eventUtils.js";
import NotificationCard from "../../components/cards/NotificationsCard.js";
import { clearNotifications } from "../../services/bars.js";
import { Icon } from 'semantic-ui-react'
import LoggedButNotSubscribedScreen from "../general/LoggedButNotSubscribedScreen.js";

const BarDashboardScreen = () => {
  const [range, setRange] = useState("30days");
  const { barUser } = useBarAuthStore();

  if (barUser === null) {
    return <div>Chargement...</div>;
  }

  if(!barUser.isSubscribed){
    return(
      <LoggedButNotSubscribedScreen/>
    );
  }

  
  return (
    <div>
      <Sidebar />
      <div className="dashboard-content">
        <div className="bar-header">
        <Icon name='info circle' size="large" color="blue"/>
          <h4>Bienvenue sur votre tableau de bord.</h4>
        </div>
        <div className="content">
          <div className="reservation-chart card">
            <div className="chart-header">
              <h3>Nombre de réservation</h3>
              <div className="chart-controls">
                <button
                  className="chart-control-button"
                  onClick={() => setRange("12months")}
                >
                  12 mois
                </button>
                <button
                  className="chart-control-button"
                  onClick={() => {
                    setRange("30days");
                  }}
                >
                  30 jours
                </button>
                <button
                  className="chart-control-button"
                  onClick={() => {
                    setRange("7days");
                  }}
                >
                  7 jours
                </button>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={formatEventsForLineChart(barUser.bookings, range)}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="reservations"
                  stroke="#4373a7"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="total-reservations card">
            <h3>
              {barUser.bookings.length}{" "}
              <span
                className={
                  calculateEventVariation(barUser.bookings).includes("+")
                    ? "positive-percent"
                    : "negative-percent"
                }
              >
                {calculateEventVariation(barUser.bookings)}
              </span>
            </h3>
            <p>réservations total</p>
          </div>

          <div className="notifications card">
            <div className="notifications-header">
              <h3>Notifications</h3>
              <button
                className="clear-all"
                onClick={() => {
                  clearNotifications(barUser.id);
                  barUser.notifications = [];
                }}
              >
                Tout supprimer <FaTimes color="red" />
              </button>
            </div>
            <ul className="notifications-list">
              {barUser.notifications.map(
                (notificationData, notificationIndex) => (
                  <NotificationCard
                    key={notificationIndex}
                    barId={barUser.id}
                    notificationData={notificationData}
                    index={notificationIndex}
                    date={notificationData.date.seconds}
                    eventTimestamp={notificationData.eventDate.seconds}
                  />
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarDashboardScreen;
