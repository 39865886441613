// BarLoadingScreen.js
import React from 'react';

const LoadingScreen = () => {;

  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingScreen;
