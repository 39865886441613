import React, { useEffect, useState } from "react";
import "./BarBookingScreen.css";
import Sidebar from "../../components/navBars/Sidebar";
import { Icon } from "semantic-ui-react";
import { format, startOfWeek, endOfWeek, subWeeks, addWeeks, eachDayOfInterval } from "date-fns";
import { fr } from "date-fns/locale"; // Importation de la locale française
import useBarAuthStore from "../../stores/bar_auth_store";
import { fetchBookingsData } from "../../services/bars";
import LoadingScreen from "../../screens/general/LoadingScreen";
import EventModal from "../../components/modals/EventModal";
import LoggedButNotSubscribedScreen  from "../general/LoggedButNotSubscribedScreen";
import { useSearchParams } from 'react-router-dom';


const BarBookingScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const { barUser } = useBarAuthStore();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [highlightDate, setHighlightDate] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = () => {
      fetchBookingsData(barUser.id).then((bookingsData) => { 
        setBookings(bookingsData);
        setLoading(false);
      });
    };
    fetchData();
  }, [barUser.id]);

  useEffect(() => {
    const timestampParam = searchParams.get("timestamp");
    if (timestampParam) {
      const timestamp = new Date(parseInt(timestampParam) * 1000);
      setCurrentWeek(timestamp);
      setHighlightDate(format(timestamp, 'yyyy-MM-dd'));
    }
  }, [searchParams]);

  const handlePrevWeek = () => {
    setCurrentWeek((prevWeek) => subWeeks(prevWeek, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => addWeeks(prevWeek, 1));
  };

  const startOfWeekDate = startOfWeek(currentWeek, { weekStartsOn: 1 });
  const endOfWeekDate = endOfWeek(currentWeek, { weekStartsOn: 1 });

  const formattedStartOfWeek = format(startOfWeekDate, "dd MMM", { locale: fr });
  const formattedEndOfWeek = format(endOfWeekDate, "dd MMM", { locale: fr });

  const weekDays = eachDayOfInterval({ start: startOfWeekDate, end: endOfWeekDate });

  const reservationsByDay = weekDays.map(day => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    const dayName = format(day, 'EEEE dd MMM', { locale: fr });
    const reservationsForDay = bookings.filter(res => {
      const eventDate = new Date(res.event.date._seconds * 1000);
      return format(eventDate, 'yyyy-MM-dd') === formattedDay;
    });
    return {
      date: formattedDay,
      day: dayName,
      reservations: reservationsForDay,
    };
  });

  const openModal = (booking) => {
    setSelectedBooking(booking);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBooking(null);
    setModalOpen(false);
  };

  if (!barUser.isSubscribed) {
    return (
      <LoggedButNotSubscribedScreen />
    );
  }

  if (loading) return (<LoadingScreen />);

  return (
    <div>
      <Sidebar />

      <div className="reservations-container">
        <div className="bar-header">
          <Icon name="info circle" size="large" color="blue" />
          <h4>Bienvenue sur votre calendrier des réservations.</h4>
        </div>
        <div className="date-range">
          <button onClick={handlePrevWeek}>&lt;</button>
          <span>{`${formattedStartOfWeek} - ${formattedEndOfWeek}`}</span>
          <button onClick={handleNextWeek}>&gt;</button>
        </div>
        <br />
        <div className="legend">
          <span className="legend-item finished">Passé</span>
          <span className="legend-item validated">Validé</span>
          <span className="legend-item waitingForValidation">À valider</span>
          <span className="legend-item rejected">Refusé</span>
          <span className="legend-item ongoing">En cours</span>
        </div>
        <div className="reservations-week">
          {reservationsByDay.map((dayData, index) => (
            <div
              className={`reservations-day ${dayData.date === highlightDate ? 'highlight' : ''}`}
              key={index}
            >
              <h2>{dayData.day}</h2>
              {dayData.reservations.length > 0 ? (
                dayData.reservations.map((res, i) => (
                  <div className={`reservation-card ${res.event.state}`} key={i} onClick={() => openModal(res)}>
                    <h3>{res.boardgame.name}</h3>
                    <p className="username">{res.players.map(player => player.username).join(', ')}</p>
                    <div className="event-details">
                      <p>{format(new Date(res.event.date._seconds * 1000), 'HH:mm')}</p>
                      <p className="nb-players">{res.players.length} ♟️</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-reservations">Aucune réservation</div>
              )}
            </div>
          ))}
        </div>
      </div>
      {modalOpen ?
        <EventModal open={modalOpen} close={closeModal} selectedBooking={selectedBooking} />
        :
        null
      }
    </div>
  );
};

export default BarBookingScreen;
