import emailjs from "@emailjs/browser";

export const sendAfterSignupEmail = (email) => {
  emailjs
    .send(
      "service_oz5dp3c",
      "template_qetedru",
      {
        name: "Ludomit",
        email: email,
        message:
          "Hello Ludomit, I would like to contact you about the potential collaboration., you can reach me at this email address : " + email + " . Thank you!",
      },
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      }
    )
    .then(
      () => {
        console.log("SUCCESS!");
      },
      (error) => {
        console.log("error in sending message", error.text);
      }
    );
};
