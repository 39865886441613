import React, {useRef} from "react";
import sound from '../../assets/sounds/notification.mp3';

const Test = () => {
  const audioRef = useRef(sound);

  const playAudio = () => {
    try {
      console.log(audioRef)
    audioRef.current.play();
    } catch (error) {
      console.error("Erreur lors de la lecture du son:", error);
    }
    
  };

  return (
    <div>
      
      <h2>Test Data</h2>
      <div>
      <button onClick={playAudio}>Play</button>
    </div>
    <audio ref={audioRef} src={sound} />
    </div>
  );
};

export default Test;
