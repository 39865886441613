// Dashboard.js
import React, { useEffect, useState } from "react";
import "./BoarGameCard.css";
import { getBoardGameWithId } from "../../services/boardgames";
import { Icon } from "semantic-ui-react";

const BoardGameCard = ({ boardGameId, handleSelect, boardGame, handleDelete }) => {
  const [selectedBoardGame, setBoardGame] = useState({}); //[boardGame, setBoardGame
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (boardGameId !== "" && boardGame == null) {
      getBoardGameWithId(boardGameId).then((result) => {
        setBoardGame(result);
      });
    } else {
      setBoardGame(boardGame);
    }
    setLoading(false);
  }, [boardGameId, boardGame]);

  const getRating = () => {
    if(selectedBoardGame.ludomitReview == null){
      return 0
    }
    else{
      var ludomitRating = selectedBoardGame.ludomitReview.rating
      var reviews = selectedBoardGame.reviews.map(review => review.rating)
      var totalRating = reviews.reduce((a, b) => a + b, 0)
      var averageRating = totalRating/reviews.length
      var rating = (ludomitRating + averageRating)/2
      return rating
    }
    
  }

  if (loading) {
    return (
      <li>
        <p>loading ...</p>
      </li>
    );
  } else {
    return <div className="game-card" onClick={() => handleSelect(selectedBoardGame)}>
    <img src={selectedBoardGame.image} alt={selectedBoardGame.name} className="boardgame-image" />
    <div className="game-info">
    <h2>{selectedBoardGame.name}</h2>
    <h4>{selectedBoardGame.category}</h4>
    <h4>{selectedBoardGame.nbPlayers}</h4>
    <h4>{getRating()}</h4>
    </div>
    {handleDelete && <Icon size="large" name='trash' color="red" onClick={() => handleDelete(selectedBoardGame.id)} />}
  </div>;
  }
};

export default BoardGameCard;
