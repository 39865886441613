import { React } from "react";
import { Icon } from 'semantic-ui-react';
import { logout } from "../../services/auth";

const AccountNotActivatedScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <h1
        style={{
          color: "#4CAF50",
          fontSize: "2.5em",
          marginBottom: "20px",
        }}
      >
        Votre compte est en cours de vérification !
      </h1>

      <h2
        style={{
          color: "#333",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Votre compte est actuellement en cours de vérification. Vous pouvez nous contacter par email si vous avez des questions.
      </h2>
      <div
        className="contact_email"
        onClick={() => {
          navigator.clipboard.writeText("contact@ludomit.com");
        }}
      >
        <h3
          style={{
            color: "#ff5722",
            marginBottom: "20px",
          }}
        >
          contact@ludomit.com
        </h3>
        <Icon name="copy" size="large" />
        
      </div>
      

      <h2
        style={{
          color: "#333",
          textAlign: "center",
        }}
      >
        À bientôt !
      </h2>
      <button className="small_button_blue_background" type="submit" onClick={()=>{logout()}}>Se deconnecter</button>
    </div>
  );
};

export default AccountNotActivatedScreen;
