import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { firestore, auth, functions } from "./firebase";
import { Bar } from "../models/bar_model";
import { addShowDetails } from "../utils/barUtils";
import { httpsCallable } from "firebase/functions";

export const verifyBarState = async () => {
  try {
    const uid = auth.currentUser.uid;
    const docRef = doc(firestore, "bars", uid);
    const docSnap = await getDoc(docRef);
    console.log(docSnap);
    const isSubscribe = docSnap.data().isSubscribed;
    var result;
    switch (isSubscribe) {
      case true:
        result = "Bar is subscribed";
        break;
      case false:
        result = "Bar is not subscribed";
        break;
      default:
        result = "Bar is not signed";
        break;
    }
    return result;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const getBarWithId = async (barId) => {
  try {
    const docRef = doc(firestore, "bars", barId);
    const docSnap = await getDoc(docRef);
    const result = Bar.fromFirestore(docSnap.id, docSnap.data());
    return result;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const clearNotifications = async (barId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      notifications: [],
    });
    console.log("Notifications cleared successfully");
  } catch (error) {
    console.error("Error clearing notifications: ", error);
  }
};

export const removeNotificationAtIndex = async (barId, index) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    const barDocSnapshot = await getDoc(barDocRef);
    if (barDocSnapshot.exists()) {
      const barData = barDocSnapshot.data();
      const notifications = barData.notifications || [];
      if (index >= 0 && index < notifications.length) {
        notifications.splice(index, 1);
        await updateDoc(barDocRef, {
          notifications: notifications,
        });
        console.log("Notification removed successfully");
      } else {
        console.error("Index out of bounds");
      }
    } else {
      console.error("Document does not exist");
    }
  } catch (error) {
    console.error("Error removing notification: ", error);
  }
};

export const updateBarField = async (barId, field, value) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      [field]: value,
    });
  } catch (error) {
    console.error(`Error updating field ${field}: `, error);
  }
};

export const addBoardGame = async (barId, boardGameId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      ludotheque: arrayUnion(boardGameId),
    });
  } catch (error) {
    console.error(
      `Error adding board game ${boardGameId} to bar ${barId}: `,
      error
    );
  }
};

export const removeBoardGame = async (barId, boardGameId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    await updateDoc(barDocRef, {
      ludotheque: arrayRemove(boardGameId),
    });
  } catch (error) {
    console.error(
      `Error removing board game ${boardGameId} from bar ${barId}: `,
      error
    );
  }
};

export const getBarSchedule = async (barId) => {
  const barDocRef = doc(firestore, "bars", barId);
  try {
    const docSnap = await getDoc(barDocRef);
    if (docSnap.exists()) {
      const schedule = docSnap.data().schedule || {};
      const scheduleWithShowDetails = addShowDetails(schedule);
      return scheduleWithShowDetails;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
};

export const fetchAnalyticsData = async (barId) => {
  const getAnalytics = httpsCallable(functions, "getAnalytics");

  try {
    const result = await getAnalytics({ barId });
    const { events, mostPopularGames } = result.data;
    return { events, mostPopularGames };
  } catch (error) {
    console.error("Error fetching analytics:", error);
    throw error;
  }
};

export const fetchBookingsData = async (barId) => {
  const getBookings = httpsCallable(functions, "getBookings");

  try {
    const result = await getBookings({ barId });
    // Retourner les données si nécessaire
    return result.data;
  } catch (error) {
    console.error("Error fetching analytics:", error);
    throw error;
  }
};

