import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useBarAuthStore from '../../stores/bar_auth_store';
import BarLoadingScreen from '../../screens/bars/BarLoadingScreen';
import useMessageStore from '../../stores/message_store';
import sound from '../../assets/sounds/notification.mp3';

const ProtectedRoute = ({ element: Component, redirectRoute, ...rest }) => {
  const { isAuthenticated, barUser, updateBarUser, logout } = useBarAuthStore();
  const { showMessage } = useMessageStore();
  const [notification, setNotification] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    let unsubscribe = () => {};

    if (isAuthenticated) {
      unsubscribe = updateBarUser();

      if (barUser === null) {
        logout();
      }
    }

    return () => unsubscribe();
  }, [isAuthenticated, updateBarUser, barUser, logout]);


  useEffect(() => {
    if (barUser && barUser.notifications && barUser.notifications.length > 0) {
      const mostRecentNotification = barUser.notifications.reduce((latest, current) => {
        return current.date.seconds > latest.date.seconds ? current : latest;
      });

      //console.log("Most recent notification:", fromSecondsToDate(mostRecentNotification.date.seconds));

      if (
        mostRecentNotification &&
        mostRecentNotification.date.seconds !== notification?.date.seconds && notification !== null
      ) {
        showMessage("Vous avez une nouvelle notification", 'info');
        setNotification(mostRecentNotification);
        if (audioRef.current) {
          audioRef.current.play().catch(error => console.error("Erreur lors de la lecture du son:", error));
        }
      }
      else if (notification === null) {
        setNotification(mostRecentNotification);
      }
    }
  }, [barUser, showMessage, notification]);


  if (isAuthenticated === null) {
    return <BarLoadingScreen />;
  }

  return (
    <>
      <audio ref={audioRef} src={sound} />
      {isAuthenticated ? <Component {...rest} /> : <Navigate to={redirectRoute} />}
    </>
  );
};

export default ProtectedRoute;
