import { React, useState } from "react";
import { auth } from "../../services/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import useMessageStore from "../../stores/message_store";


const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const{showMessage} = useMessageStore.getState();

  return (
    <div id="BarSingupScreen" style={
      {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }
    
    }>
      <h1>Reinitialiser votre mot de passe</h1>
      <h2>Pour reinitialiser votre mot de passe, veuillez entrer votre email ci-dessous</h2>
        <label htmlFor="email">Email</label>
        <input
        className="input"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
    
        <br/>
        <button className="small_button_blue_background" type="submit" onClick={()=>{
sendPasswordResetEmail(auth, email).then(() => {
    console.log("Email de réinitialisation envoyé");
    showMessage("L'email a bien été envoyé", "success");
}).catch((error) => {
    console.log("Erreur lors de l'envoi de l'email de réinitialisation", error);
    showMessage("Erreur lors de l'envoi de l'email de réinitialisation", "error");
});
        }}>Envoyer</button>
    </div>
  );
};

export default ResetPasswordScreen;
