import React, {useEffect} from "react";
import "./HomeScreen.css"; // Assurez-vous d'ajouter les styles CSS
import { Icon } from "semantic-ui-react";
import logo from "../../assets/logo_no_bg.png"; // Assurez-vous d'ajouter le logo

const HomeScreen = () => {
  const scrollToSection = (index) => {
    const sections = document.querySelectorAll('.section');
    sections[index].scrollIntoView({ behavior: 'smooth' });
  };
  
  useEffect(() => {
    let currentSection = 0;
    const sections = document.querySelectorAll('.section');
    const handleScroll = (event) => {
      if (event.deltaY > 0) {
        // Scrolling down
        if (currentSection < sections.length - 1) {
          currentSection++;
          scrollToSection(currentSection);
        }
      } else {
        // Scrolling up
        if (currentSection > 0) {
          currentSection--;
          scrollToSection(currentSection);
        }
      }
    };
  
    window.addEventListener('wheel', handleScroll);
  
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div className="homepage">
      <header className="header">
  <div className="header-content">
    <img src={logo} alt="LudoMit" className="logo" />
    <div className="header-buttons">
      <a href="/bar/login" className="header-button">Connexion</a>
      <a href="/bar/signup" className="header-button">Inscription</a>
    </div>
  </div>
</header>


      <div className="section-container">
        <section className="section title-section">
          <h1>Bienvenue sur LudoMit</h1>
          <h2>Fédérez la communauté des joueurs de jeux de plateau et organisez des rencontres inoubliables dans des bars à jeux.</h2>
        </section>

        <section className="section value-proposition-section">
          <h2>Proposition de Valeur</h2>
          <div className="features-grid">
            <div className="feature-item">
              <Icon name="comment" size="huge" />
              <h3>Forum</h3>
              <p>Discutez de vos jeux de plateau préférés avec notre communauté.</p>
            </div>
            <div className="feature-item">
              <Icon name="comments" size="huge" />
              <h3>Chat</h3>
              <p>Communiquez en temps réel avec d'autres joueurs.</p>
            </div>
            <div className="feature-item">
              <Icon name="book" size="huge" />
              <h3>Reviews</h3>
              <p>Lisez et écrivez des critiques sur vos jeux de plateau préférés.</p>
            </div>
            <div className="feature-item">
              <Icon name="chess board" size="huge" />
              <h3>Création d'Événements</h3>
              <p>Organisez des rencontres et des événements dans des bars à jeux.</p>
            </div>
          </div>
        </section>

        <section className="section contact-section">
          <h2>Contactez-nous</h2>
          <form className="contact-form">
            <input type="text" placeholder="Nom" required />
            <input type="email" placeholder="Email" required />
            <textarea placeholder="Message" required></textarea>
            <button type="submit">Envoyer</button>
          </form>
        </section>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>© 2023 LudoMit. Tous droits réservés.</p>
          <div className="footer-links">
            <a href="/terms-of-services">CGU</a>
            <a href="/legal-notice">Mentions Légales</a>
            <a href="/privacy-policy">Politique de Confidentialité</a>
          </div>
          <div className="footer-socials">
            <a href="https://facebook.com"><Icon name="facebook" /></a>
            <a href="https://twitter.com"><Icon name="twitter" /></a>
            <a href="https://instagram.com"><Icon name="instagram" /></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeScreen;


