import React from "react";

const PrivacyPolicyScreen = () => {

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
        
    }}>
        <h1>En construction</h1>
    </div>
  );
};

export default PrivacyPolicyScreen;


