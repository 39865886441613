// Support.js
import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import './SupportScreen.css';

const SupportScreen = () => {
  return (
    <div className="support-content">
      <div className="header">
        <h2>Support Ludomit</h2>
        <p>Nous sommes là pour vous aider. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.</p>
      </div>

      <div className="contact-methods card">
        <h3>Contactez-nous</h3>
        <div className="contact-method">
          <FaEnvelope className="contact-icon" />
          <div>
            <h4>Email</h4>
            <p><a href="mailto:contact@ludomit.com">contact@ludomit.com</a></p>
          </div>
        </div>
        <div className="contact-method">
          <FaPhone className="contact-icon" />
          <div>
            <h4>Téléphone</h4>
            <p>+33 1 23 45 67 89</p>
          </div>
        </div>
      </div>

      <div className="faq card">
        <h3>FAQ</h3>
        <div className="faq-item">
          <h4>Comment puis-je m'abonner ?</h4>
          <p>Pour vous abonner, veuillez visiter notre page d'abonnement et suivre les instructions.</p>
        </div>
        <div className="faq-item">
          <h4>Comment modifier mes informations de profil ?</h4>
          <p>Vous pouvez modifier vos informations de profil en vous rendant sur la page de profil et en cliquant sur l'icône de crayon.</p>
        </div>
        <div className="faq-item">
          <h4>Comment ajouter un nouveau jeu à ma ludothèque ?</h4>
          <p>Pour ajouter un nouveau jeu à votre ludothèque, cliquez sur le bouton "Ajouter un jeu" sur la page de profil.</p>
        </div>
        {/* Ajoutez plus de FAQ ici si nécessaire */}
      </div>
    </div>
  );
};

export default SupportScreen;
